<template>
  <MainLayout>
    <!-- popup подтверждающий удаление -->
    <DeletePopupAdmin
      title="администратора"
      :name="fullName"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteAdmin"
    />
    <!-- /popup подтверждающий удаление-->

    <template v-slot:block>
      {{
        sourceObject.results
          ? sourceObject.results.is_active === false
            ? "Заблокирован -"
            : ""
          : ""
      }}
    </template>
    <template v-slot:navbar-title
      >{{ sourceObject.results ? sourceObject.results.name : "" }}
    </template>
    <template v-slot:navbar-btn-wrap>
      <DeletePopup
        name="Дилера"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :delete-function="deleteCompany"
      />
      <BlockPopup
        v-if="sourceObject.results && sourceObject.results.is_active"
        name="Дилера"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :block-function="blockDealer"
      />
      <UnBlockPopup
        v-if="!(sourceObject.results && sourceObject.results.is_active)"
        name="Дилера"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :un-block-function="unBlockDealer"
      />
      <router-link
        v-if="urlQuery && urlQuery.id"
        :to="`/companyEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать
      </router-link>
      <!--      <button class="btn btn-edit btn_color_grey">редактировать</button>-->
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <InfoLayout :tabs="infoTabs">
          <template v-slot:deal>
            <div class="keep__title">
              <p class="keep__text">Договор и оплата</p>
              <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
            </div>
            <BasicTableWithoutHeader
              :table-data="getDataDeals"
              v-if="sourceObject.results"
            >
              <template v-slot:item.2.value="{ item }">
                <a download :href="item.value">{{
                  sourceObject.results.contract
                    ? `Договор № ${sourceObject.results.contract.number}`
                    : "-"
                }}</a>
              </template>
            </BasicTableWithoutHeader>
          </template>
          <template v-slot:recv>
            <div class="keep__title">
              <p class="keep__text">Реквизиты</p>
              <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
            </div>
            <BasicTableWithoutHeader
              :table-data="getDataRequisites"
              v-if="sourceObject.results"
            />
          </template>
          <template v-slot:admins>
            <div class="keep__title">
              <p class="keep__text">Администраторы</p>
              <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
              <router-link
                to="createAdmin"
                append
                class="btn btn-add btn_color_green"
                >Добавить Администратора
              </router-link>
            </div>

            <BasicTable
              :header="headers"
              :table-data="getDataAdmins.results"
              deleteButtonDirectionStart
            >
              <template v-slot:delete-td-start="{ itemChild }">
                <td class="btn-delete-wrap">
                  <button
                    @click="clickDelete(itemChild)"
                    class="form__btn-delete btn btn-delete"
                    style="background-color: #eb5c6d"
                  ></button>
                </td>
              </template>
            </BasicTable>
          </template>
          <!--          <template v-slot:partnerPoint>-->
          <!--            <div class="d-flex align-center justify-space-between" >-->
          <!--            <div class="keep__title ">-->
          <!--              <p class="keep__text">Точка партнера</p>-->
          <!--              &lt;!&ndash;              <button class="btn btn_color_grey btn-edit">Редактировать</button>&ndash;&gt;-->
          <!--            </div>-->
          <!--              <router-link-->
          <!--                  to="partnerPointCreate"-->
          <!--                  append-->
          <!--                  class="btn btn-add btn_color_green mr-5"-->
          <!--              >Добавить</router-link-->
          <!--              >-->
          <!--            <router-link-->
          <!--                v-if="urlQuery && urlQuery.id"-->
          <!--                :to="`/childrenEdit/` + urlQuery.id"-->
          <!--                class="btn btn-edit btn_color_grey"-->
          <!--            >редактировать</router-link-->
          <!--            >-->
          <!--            </div>-->
          <!--              -->
          <!--            <BasicTableWithoutHeader-->
          <!--                :table-data="getDataRequisites"-->
          <!--                v-if="!sourceObject.results"-->
          <!--            />-->
          <!--            <div v-else class=" list container wrapper">-->
          <!--              <span class="ma-0 justify-center object__term-text text-align_center" style="width: 100%;">Точка не создана</span>-->
          <!--            </div>-->
          <!--          </template>-->
          <template v-slot:partnerPoint>
            <div class="keep__title">
              <p class="keep__text">Точка партнера</p>
              <btn class="btn btn-add btn_color_green">Добавить точку </btn>
              <router-link
                to="createAdmin"
                append
                class="btn btn-edit btn_color_grey ml-4"
                >редактировать
              </router-link>
            </div>
            <BasicTableWithoutHeader
              :table-data="getDataRequisites"
              v-if="sourceObject.results"
            />
            <!--            <BasicTable-->

            <!--                :header="headers"-->
            <!--                :table-data="getDataDealerSplit.results"-->
            <!--            >-->
            <!--            </BasicTable>-->
            <!--            <div v-else class=" list container wrapper">-->
            <!--              <span class="ma-0 justify-center object__term-text text-align_center" style="width: 100%;">Точка не создана</span>-->
            <!--            </div>-->
          </template>
          <template v-slot:acquiring>
            <BasicTableWithoutHeader
              :table-data="getDataRequisites"
              v-if="!sourceObject.results"
            />
            <div v-else class="">
              <FormInputBlock>
                <CreateFormLayout>
                  <template v-slot:title-flex-right>
                    <div></div>
                  </template>
                  <template style="display: none" v-slot:title__text>
                    Эквайринг
                  </template>
                  <template>
                    <!--    Данные компании    -->

                    <div>
                      <FormInputBlock>
                        <ValidationSelectField
                          v-model="validity"
                          :items="[
                            { text: 'Сбербанк', value: 1 },
                            { text: 'Тинькофф', value: 2 }
                          ]"
                          rules="required"
                          label="Тип эквайринга"
                          validate-name="тип эквайринга"
                        ></ValidationSelectField>
                      </FormInputBlock>
                      <FormCardTitle>
                        <p class="data__text">Карта доступа</p>
                      </FormCardTitle>
                      <FormInputBlock>
                        <ValidationInputField
                          rules="required"
                          mask="##"
                          label="Процент комиссии"
                          v-model="cardsDiscountPercent"
                          validate-name="процент комиссии"
                        ></ValidationInputField>
                        <ValidationInputField
                          rules="required"
                          label="Минимальная сумма, руб."
                          v-model="cardMinSumm"
                          validate-name="минимальная сумма, руб."
                        ></ValidationInputField>
                      </FormInputBlock>
                      <FormCardTitle>
                        <p class="data__text">Бесключевой доступ, 3 месяца</p>
                      </FormCardTitle>
                      <FormInputBlock>
                        <ValidationInputField
                          rules="required"
                          mask="##"
                          label="Процент комиссии"
                          v-model="appDiscountPercent_3"
                          validate-name="процент комиссии"
                        ></ValidationInputField>
                        <ValidationInputField
                          rules="required"
                          label="Минимальная сумма, руб."
                          v-model="appMinSum_3"
                          validate-name="минимальная сумма, руб."
                        ></ValidationInputField>
                      </FormInputBlock>
                      <FormCardTitle>
                        <p class="data__text">Бесключевой доступ, 12 месяца</p>
                      </FormCardTitle>
                      <FormInputBlock>
                        <ValidationInputField
                          rules="required"
                          mask="##"
                          label="Процент комиссии"
                          v-model="appDiscountPercent_12"
                          validate-name="процент комиссии"
                        ></ValidationInputField>
                        <ValidationInputField
                          rules="required"
                          label="Минимальная сумма, руб."
                          v-model="appMinSum_12"
                          validate-name="минимальная сумма, руб."
                        ></ValidationInputField>
                      </FormInputBlock>
                    </div>
                  </template>
                  <template v-slot:btns="{ handleSubmit }">
                    <a @click="$router.go(-1)" class="btn btn_color_white">
                      Отмена
                    </a>
                    <v-btn
                      type="submit"
                      @click="handleSubmit(createAccounts)"
                      class="btn btn-green"
                    >
                      Сохранить
                    </v-btn>
                  </template>
                </CreateFormLayout>
              </FormInputBlock>
            </div>
          </template>
        </InfoLayout>
      </template>
      <template v-slot:organizations>
        <DealersCompany />
      </template>
      <template v-slot:kindergarden>
        <DealersKindergarden />
      </template>
      <template v-slot:objects>
        <DealersObject />
      </template>
      <template v-slot:controllers>
        <DealerControllers />
      </template>
      <template v-slot:payments>
        <DealerPayments />
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import InfoLayout from "@/components/MoreInfo/InfoLayout";
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTable from "@/components/tables/BasicTable";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import * as query from "@/helpers/query";
import DealersCompany from "@/components/info/dealers/DealersCompany";
import DealersKindergarden from "@/components/info/dealers/DealersKindergarden";
import DealersObject from "@/components/info/dealers/DealersObject";
import DealerControllers from "@/components/info/dealers/DealerControllers";
import DealerPayments from "@/components/info/dealers/DealerPayments";
import DeletePopup from "@/components/popup/DeletePopup";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import BlockPopup from "@/components/popup/BlockPopup";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import DeletePopupAdmin from "@/components/popup/DeletePopupCommon";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField.vue";
import CreateFormLayout from "@/components/layouts/CreateFormLayout.vue";
import ValidationInputField from "@/components/ValidationField/ValidationInputField.vue";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle.vue";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock.vue";

export default {
  name: "DillerInfosPages",
  components: {
    FormInputBlock,
    FormCardTitle,
    ValidationInputField,
    CreateFormLayout,
    ValidationSelectField,
    UnBlockPopup,
    BlockPopup,
    DeletePopup,
    DealerPayments,
    DealerControllers,
    DealersObject,
    DealersCompany,
    DealersKindergarden,
    BasicTableWithoutHeader,
    BasicTable,
    SlideLayout,
    MainLayout,
    InfoLayout,
    DeletePopupAdmin
  },
  data: () => ({
    dialog: false,
    idAdmin: "", // id админа
    fullName: "",

    types: "diller",
    queries: query.organizations,
    infoTabs: [
      {
        text: "Договор и оплата",
        value: "deal"
      },
      {
        text: "Реквизиты",
        value: "recv"
      },
      {
        text: "Администраторы",
        value: "admins"
      }
      // {
      //   text: "Точка партнера",
      //   value: "partnerPoint"
      // },
      // {
      //   text: "Эквайринг",
      //   value: "acquiring"
      // }
    ],
    headers: [
      {
        value: "id",
        text: "ID"
      },
      {
        renderFunction: value => {
          return `<div>${value.last_name || ""} ${value.first_name ||
            ""} ${value.middle_name || ""}</div>`;
        },
        text: "ФИО"
      },
      {
        renderFunction: value => phoneFilter.execute(value?.phone_number),
        text: "Телефон"
      }
    ]
  }),
  methods: {
    deleteCompany() {
      this.$store
        .dispatch("deleteCompany", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.dealer)
          );
        });
    },
    blockDealer() {
      this.$store
        .dispatch("blockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.dealer)
          );
        });
    },
    unBlockDealer() {
      this.$store
        .dispatch("unBlockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.unBan(successMessageDictionary.dealer)
          );
        });
    },

    clickDelete(obj) {
      this.idAdmin = obj.id;
      this.fullName = `${obj.last_name} ${obj.first_name} ${obj.middle_name}`;
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteAdmin() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteAdmin", {
          id: this.idAdmin,
          organizationId: this.$route.params.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.admin)
          );
        })
        .finally(() => {
          this.$store.dispatch("fetchAdmins").finally(() => {
            this.isDateLoaded = false;
          });
        });
    }
  },
  computed: {
    sourceObject() {
      return this.$store.getters.getDealerInfo;
    },
    getDataAdmins() {
      return this.$store.getters.getAdmins;
    },
    getDataDealerSplit() {
      return this.$store.getters.dealerSplit;
    },
    getDataDeals() {
      return [
        {
          text: "Номер договора",
          value: this.sourceObject.results.contract
            ? this.sourceObject.results.contract.number
            : "-"
        },
        {
          text: "Дата договора",
          value: this.sourceObject.results.contract
            ? moment(this.sourceObject.results.created_at * 1000).format(
                "DD.MM.YYYY"
              )
            : "-"
        },
        {
          text: "Файл (скан) договора",
          value: this.sourceObject.results.contract
            ? this.sourceObject.results.contract.file
            : "-"
        },
        {
          text: "Тип оплаты",
          value:
            dictionariesHelper.acquiringEntity[
              this.sourceObject.results.acquiring_entity
            ]
        },
        {
          text: "Эквайринг",
          value:
            dictionariesHelper.acquiringProvider[
              this.sourceObject.results.acquiring_provider
            ]
        },
        {
          text: "Дилер ВБ",
          value: this.sourceObject.results.is_vb_diller ? "Да" : "Нет"
        }
      ];
    },
    getDataRequisites() {
      return [
        {
          text: "Полное название организации",
          value: this.sourceObject.results.full_org_name
        },
        {
          text: "Краткое название организации",
          value: this.sourceObject.results.name
        },
        {
          text: "Город",
          value: this.sourceObject.results.city_details.name
        },
        {
          text: "Тип организации",
          value: this.sourceObject.results.ownership
        },
        {
          text: "Руководитель",
          value: this.sourceObject.results.director
        },
        {
          text: "ИНН",
          value: this.sourceObject.results.TIN
        },
        {
          text: "ОРГН",
          value: this.sourceObject.results.PSRN
        },
        {
          text: "БИК",
          value: this.sourceObject.results.RCBIC
        },
        {
          text: "КПП",
          value: this.sourceObject.results.IEC
        },
        {
          text: "Название банка",
          value: this.sourceObject.results.bank
        },
        {
          text: "К/c",
          value: this.sourceObject.results.CA
        },
        {
          text: "Р/c",
          value: this.sourceObject.results.giro
        },
        {
          text: "Адрес организации",
          value: this.sourceObject.results.address
        },
        {
          text: "Фактический адрес организации",
          value: this.sourceObject.results.actual_address
        },

        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.results?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.results.email
        }
      ];
    },
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        },
        {
          text: "Компании",
          value: "organizations",
          count: this.sourceObject.results
            ? this.sourceObject.results.diller_clients_count
            : "-",
          icon: null
        },
        {
          text: "Детские сады",
          value: "kindergarden",
          count: this.sourceObject.results
            ? this.sourceObject.results.diller_kindergartens_count
            : "-",
          icon: null
        },
        {
          text: "Объекты",
          value: "objects",
          count: this.sourceObject.results
            ? this.sourceObject.results.diller_objects_count
            : "-",
          icon: null
        },
        {
          text: "Контроллеры",
          value: "controllers",
          count: this.sourceObject.results
            ? this.sourceObject.results.diller_controllers_count
            : "-",
          icon: null
        },
        {
          text: "Платежи",
          value: "payments",
          icon: null
        }
      ];
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, diller: this.urlQuery.id }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchDealerInfo").finally(() => {
      this.isDateLoaded = false;
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchAdmins").finally(() => {
      this.isDateLoaded = false;
    });
    // this.$store.dispatch("fetchDealerSplitInfo").finally(() => {
    //   this.isDateLoaded = false;
    // });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchDealerInfo").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped>
.wrapper {
  height: 50vh;
  display: flex;
  align-items: center;
}
</style>
